<template>
  <!-- 添加或修改业务对话框 -->
  <el-dialog :title="title" :visible.sync="open" width="800px" :close-on-click-modal="false" append-to-body>
    <el-form ref="supply" rules="rules" :model="supply" label-width="80px">
      <el-form-item label="项目名称" prop="title">
        <el-input v-model="demand_name" placeholder="请输入项目名称" readonly/>
      </el-form-item>
      <!-- <el-form-item label="产品单价" prop="price">
        <el-input v-model="supply.price" placeholder="请输入产品单价"/>
      </el-form-item>
	  <el-form-item label="供应数量" prop="num">
	    <el-input v-model="supply.num" placeholder="请输入供应数量"/>
	  </el-form-item> -->
	  <!-- <el-form-item label="供应产品" prop="goods_id">
	    <el-input v-model="supply.bizCode" placeholder="请输入供应产品"/>
	  </el-form-item> -->
	  <el-form-item label="营业执照" prop="images">
	    <el-upload
	    	ref="upload"
	    	:class="{ ishide: hide[index] }"
	    	:action="uploadActionUrl"
	    	list-type="picture-card"
	    	:on-success="
	    		(file, fileList) => {
	    			return handleSuccess(file, fileList, index);
	    		}
	    	"
	    	:on-preview="handlePictureCardPreview"
	    	:on-remove="
	    		(file, fileList) => {
	    			return handleRemove(file, fileList, index);
	    		}
	    	"
	    	:on-exceed="handleExceed"
	    >
	    	<i class="el-icon-plus"></i>
	    </el-upload>
	    <el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
	    <!-- <span>共6张，还能上传{{ imgList.length<6 ? 6- imgList.length : 6 }}张</span> -->
	  </el-form-item>
	  <el-form-item label="名称" prop="name">
	  	<el-input v-model.trim="supply.name" autocomplete="off" placeholder="请输入您的名称" class="ns-len-input"></el-input>
	  </el-form-item>
	  <el-form-item label="联系电话" prop="phone">
	  	<el-input v-model.trim="supply.phone" autocomplete="off" placeholder="请输入联系电话" class="ns-len-input"></el-input>
	  </el-form-item>
	  <el-form-item label="地址" prop="full_address">
	  	<el-select v-model="supply.province_id" placeholder="请选择省" @change="changeProvice(supply.province_id)">
	  		<el-option v-for="option in province" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
	  	</el-select>
	  	<el-select v-model="supply.city_id" placeholder="请选择市" @change="changeCity(supply.city_id)">
	  		<el-option v-for="option in city" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
	  	</el-select>
	  	<el-select v-model="supply.district_id" placeholder="请选择区/县" @change="changeDistrict(supply.district_id)">
	  		<el-option v-for="option in district" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
	  	</el-select>
	  </el-form-item>
	  
	  <el-form-item label="详细地址" prop="address">
	  	<el-input v-model.trim="supply.address" autocomplete="off" placeholder="定位到小区、街道、写字楼" class="ns-len-input"></el-input>
	  </el-form-item>
	  <el-form-item label="备注" prop="desc">
	    <el-input v-model="supply.desc" placeholder="请输入备注"/>
	  </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
	import {SupplyForm} from '@/api/goods/goods';
	import {mapGetters} from 'vuex';
	import { addressInfo } from "@/api/member/member"
	import { getArea } from "@/api/address"
	import { orderInfo, save, uploadImg } from '@/api/order/order';
	import Config from '@/utils/config';

    export default {
        name: "testDialog",
        data() {
			let self = this
			var isMobile = (rule, value, callback) => {
			    if (!value) {
			        return callback(new Error("手机号不能为空"))
			    } else {
			        const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
			
			        if (reg.test(value)) {
			            callback()
			        } else {
			            callback(new Error("请输入正确的手机号"))
			        }
			    }
			}
			var fullAddress = (rule, value, callback) => {
			    if (self.formData.province_id) {
			        if (self.formData.city_id) {
			            if (self.formData.district_id) {
			                return callback()
			            } else {
			                return callback(new Error("请选择区/县"))
			            }
			        } else {
			            return callback(new Error("请选择市"))
			        }
			    } else {
			        return callback(new Error("请选择省"))
			    }
			}
            return {
                // 弹出层标题
                title: "报名信息填写",
                // 是否显示弹出层
                open: false,
                // 表单参数
                supply: {
                    title:"",
					price:"",
					num:"",
					desc:"",
                    name: "",
                    phone: "",
                    province_id: "",
                    city_id: "",
                    district_id: "",
                    address: "",
                },
				addressValue: "",
				flag: false, //防重复标识
				defaultRegions: [],
				province: [],
				city: [],
				district: [],
				pickerValueArray: [],
				multiIndex: [0, 0, 0],
				isInitMultiArray: false,
				// 是否加载完默认地区
				isLoadDefaultAreas: false,
				loading: true,
				yes: true,
				uploadActionUrl: Config.baseUrl + '/api/upload/evaluateimg',
				goodsEvalList: [], //评价列表
				imgList: [],
				isEvaluate: 0, //判断是否为追评
				dialogImageUrl: '',
				dialogVisible: false,
				hide: [],
				value:0,
				demand_id:0,
				demand_name:'',
            };
        },
		created() {
		    this.getAddressDetail()
		    this.getDefaultAreas(0, {
		        level: 0
		    })
		},
		computed: {
		    pickedArr() {
		        // 进行初始化
		        if (this.isInitMultiArray) {
		            return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
		        }
		        return [this.pickerValueArray[0], this.city, this.district]
		    }
		},
		watch: {
		    defaultRegions: {
		        handler(arr, oldArr = []) {
		            // 避免传的是字面量的时候重复触发
		            if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
		            this.handleDefaultRegions()
		        },
		        immediate: true
		    }
		},
        methods: {
        	// 窗口初始化方法，nextTick方法可以添加逻辑，如打开窗口时查询数据填充
            init(bizId,name) {
                this.open = true;
                this.$nextTick(() => {
					this.demand_id=bizId
					this.demand_name=name
                });
            },
			getAddressDetail() {
			    addressInfo({
					id:1,
			        default: 1
			    })
			        .then(res => {
						console.log('getAddressDetail');
						console.log(res.data);
			            let data = res.data
			            if (data != null) {
			                this.supply.name = data.name
			                this.supply.phone = data.mobile
			                this.supply.address = data.address
			                this.supply.province_id = data.province_id
			                this.supply.city_id = data.city_id
			                this.supply.district_id = data.district_id
			                this.defaultRegions = [data.province_id, data.city_id, data.district_id]
			            }
			        })
			        .catch(err => {
						if(err.message==="token不存在"){
							this.$message.error('请先登录');
							this.$router.push({ path: '/login'});
						}
					})
			},
			// 异步获取地区
			getAreas(pid, callback) {
			    getArea({
			        pid: pid
			    })
			        .then(res => {
			            if (res.code == 0) {
			                var data = []
			                res.data.forEach((item, index) => {
			                    data.push(item)
			                })
			                if (callback) callback(data)
			            }
			        })
			        .catch(err => {})
			},
			handleSuccess(file, fileList, index) {
				let arr = this.imgList;
				arr = arr.concat(file.data.pic_path);
				this.imgList=arr;
				// this.value=this.value+1;
				// this.imgList= [];
				/* this.$set(this.imgList, index, arr);
				if (this.isEvaluate) {
					this.goodsEvalList[index].again_images = this.imgList.toString();
				} else {
					this.goodsEvalList[index].images = this.imgList.toString();
				} */
				if (this.imgList.length >= 6) {
					this.hide[index] = true;
				}
			},
			handleRemove(file, fileList, index) {
				let i = util.inArray(file.response.data.pic_path, this.imgList);
				this.imgList.splice(i, 1);
				if (this.isEvaluate) {
					this.goodsEvalList[index].again_images = this.imgList.toString();
				} else {
					this.goodsEvalList[index].images = this.imgList.toString();
				}
			
				if (this.imgList.length < 6) {
					this.hide[index] = false;
				}
			},
			handleExceed(file, fileList) {
				// 图片数量大于6
				this.$message.warning('上传图片最大数量为6张');
			},
			handlePictureCardPreview(file) {
				// 点开大图
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			/**
			 * 改变省
			 */
			changeProvice(id) {
				getArea({pid: id}).then(res => {
					if (res.code == 0) {
						this.city = res.data
					}
				}).catch(err => {
				    this.loading = false
				})
			    // this.getAreas(id, data => (this.city = data))
			    let obj = {}
			    obj = this.province.find(item => {
			        //这里的province就是上面遍历的数据源
			        return item.id === id //筛选出匹配数据
			    })
			    this.supply.city_id = ""
			    this.supply.district_id = ""
			    this.supply.full_address = obj.name // 设置选中的地址
			},
			/**
			 * 改变市
			 */
			changeCity(id) {
				getArea({pid: id}).then(res => {
					if (res.code == 0) {
						this.district = res.data
					}
				}).catch(err => {
			        this.loading = false
			    })
			    // this.getAreas(id, data => (this.district = data))
			    let obj = {}
			    obj = this.city.find(item => {
			        //这里的province就是上面遍历的数据源
			        return item.id === id //筛选出匹配数据
			    })
			    this.supply.district_id = ""
			    this.supply.full_address = this.supply.full_address + "-" + obj.name
			},
			/**
			 * 改变区
			 */
			changeDistrict(id) {
			    let obj = {}
			    obj = this.district.find(item => {
			        //这里的province就是上面遍历的数据源
					/* if(item.id === id){
						return item
					} */
			        return item.id === id //筛选出匹配数据
			    })
				this.supply.district_id = obj.id
				this.supply.full_address = this.supply.full_address + "-" + obj.name
				this.$forceUpdate()
			},
			getDefaultAreas(pid, obj) {
			    getArea({
			        pid: pid
			    })
			        .then(res => {
			            if (res.code == 0) {
			                var data = []
			                var selected = undefined
			                res.data.forEach((item, index) => {
			                    if (obj != undefined) {
			                        if (obj.level == 0 && obj.province_id != undefined) {
			                            selected = obj.province_id
			                        } else if (obj.level == 1 && obj.city_id != undefined) {
			                            selected = obj.city_id
			                        } else if (obj.level == 2 && obj.district_id != undefined) {
			                            selected = obj.district_id
			                        }
			                    }
			                    if (selected == undefined && index == 0) {
			                        selected = item.id
			                    }
			                    data.push(item)
			                })
			
			                this.pickerValueArray[obj.level] = data
			                if (obj.level + 1 < 3) {
			                    obj.level++
			                    this.getDefaultAreas(selected, obj)
			                } else {
			                    this.isInitMultiArray = true
			                    this.isLoadDefaultAreas = true
			                }
			
			                this.province = this.pickerValueArray[0]
			            }
			            this.loading = false
			        })
			        .catch(err => {
			            this.loading = false
			        })
			},
			handleDefaultRegions() {
			    var time = setInterval(() => {
			        if (!this.isLoadDefaultAreas) return
			        this.isInitMultiArray = false
			
			        for (let i = 0; i < this.defaultRegions.length; i++) {
			            for (let j = 0; j < this.pickerValueArray[i].length; j++) {
			                this.province = this.pickerValueArray[0]
			
			                // 匹配省
			                if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
			                    // 设置选中省
			                    this.$set(this.multiIndex, i, j)
			                    // 查询市
			                    this.getAreas(this.pickerValueArray[i][j].id, data => {
			                        this.city = data
			
			                        for (let k = 0; k < this.city.length; k++) {
			                            if (this.defaultRegions[1] == this.city[k].id) {
			                                // 设置选中市
			                                this.$set(this.multiIndex, 1, k)
			
			                                // 查询区县
			                                this.getAreas(this.city[k].id, data => {
			                                    this.district = data
			
			                                    // 设置选中区县
			                                    for (let u = 0; u < this.district.length; u++) {
			                                        if (this.defaultRegions[2] == this.district[u].id) {
			                                            this.$set(this.multiIndex, 2, u)
			                                            this.handleValueChange({
			                                                detail: {
			                                                    value: [j, k, u]
			                                                }
			                                            })
			                                            break
			                                        }
			                                    }
			                                })
			
			                                break
			                            }
			                        }
			                    })
			                }
			            }
			        }
			        if (this.isLoadDefaultAreas) clearInterval(time)
			    }, 100)
			},
			handleValueChange(e) {
			    // 结构赋值
			    let [index0, index1, index2] = e.detail.value
			    let [arr0, arr1, arr2] = this.pickedArr
			    let address = [arr0[index0], arr1[index1], arr2[index2]]
			
			    this.supply.full_address = ""
			    for (let i = 0; i < address.length; i++) {
			        if (this.supply.full_address) {
			            this.supply.full_address = this.supply.full_address + "-" + address[i].name
			        } else {
			            this.supply.full_address = this.supply.full_address + address[i].name
			        }
			    }
			},
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            /** 提交按钮 */
            submitForm: function () {
                this.$refs["supply"].validate(valid => {
					// if (this.supply.title==''||this.supply.title==undefined||this.supply.title==null) {
					// 	this.$message({ message: '标题不能为空哦', type: 'warning' });
					// 	return;
					// }
					// if (this.supply.price==''||this.supply.price==undefined||this.supply.price==null) {
					// 	this.$message({ message: '价格不能为空哦', type: 'warning' });
					// 	return;
					// }
					// if (this.supply.num==''||this.supply.num==undefined||this.supply.num==null) {
					// 	this.$message({ message: '数量不能为空哦', type: 'warning' });
					// 	return;
					// }
					if (this.supply.name==''||this.supply.name==undefined||this.supply.name==null) {
						this.$message({ message: '名称不能为空哦', type: 'warning' });
						return;
					}
					if (this.supply.phone==''||this.supply.phone==undefined||this.supply.phone==null) {
						this.$message({ message: '电话不能为空哦', type: 'warning' });
						return;
					}
					/* else{
						const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
						if (reg.test(this.supply.phone)) {
							callback()
						} else {
							callback(new Error("请输入正确的手机号"))
						}
					} */
					if (this.supply.province_id==''||this.supply.province_id==undefined||this.supply.province_id==null) {
						this.$message({ message: '请选择省份', type: 'warning' });
						return;
					}
					if (this.supply.city_id==''||this.supply.city_id==undefined||this.supply.city_id==null) {
						this.$message({ message: '请选择城市', type: 'warning' });
						return;
					}
					if (this.supply.district_id==''||this.supply.district_id==undefined||this.supply.district_id==null) {
						this.$message({ message: '请选择县/区', type: 'warning' });
						return;
					}
					if (this.imgList==''||this.imgList==undefined||this.imgList==null||this.imgList.length<=0) {
						this.$message({ message: '营业执照不能为空哦', type: 'warning' });
						return;
					}
					if (this.supply.desc==''||this.supply.desc==undefined||this.supply.desc==null) {
						this.$message({ message: '描述不能为空哦', type: 'warning' });
						return;
					}
                    if (valid) {
                        console.log(this.supply);
						console.log(this.imgList);
						SupplyForm({
							demand_id:this.demand_id,
							title:this.demand_name,
							price:this.supply.price,
							num:this.supply.num,
							name:this.supply.name,
							phone:this.supply.phone,
							address:this.supply.address,
							desc:this.supply.desc,
							// goods_id:this.supply.goods_id,
							province_id:this.supply.province_id,
							city_id:this.supply.city_id,
							district_id:this.supply.district_id,
							goods_image:this.imgList[0],
							goods_images:this.imgList,
						}).then(res => {
							console.log(res);
							if (res.code == 0) {
								this.$message.success('提交成功，请等待商家联系');
								this.$router.push({ path: '/supply'});
							}
						}).catch(err => {
							console.log(err);
							console.log(err.error_code);
							if (err.code === -1) {
								if(err.error_code==="TOKEN_NOT_EXIST"){
									this.$message({ message: '请先登录', type: 'warning' });
									this.$router.push({ path: '/login'});
								}
							}
							this.$message.error(err.message);
						})
                    }
                });
            },
        }
    };
</script>
<style>
	.el-upload--picture-card,
	.el-upload-list--picture-card .el-upload-list__item {
		width: 70px;
		height: 70px;
		line-height: 80px;
		position: relative;
	}
	.el-upload-list--picture-card .el-upload-list__item-thumbnail {
		width: 100%;
		height: auto;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.el-upload-list__item.is-success .el-upload-list__item-status-label {
		display: none;
	}
	.ishide .el-upload--picture-card {
		display: none;
	}
</style>
